<template>
  <div v-if="loaded" class="store">
    <div class="is-flex mb-5">
      <div class="is-flex is-align-items-center">
        <image-loader
          :id="merchant.logo"
          max-width="45px"
          class="merchant-logo"
        />
        <div class="is-flex is-flex-direction-column ml-2">
          <span class="is-size-4 has-text-weight-bold">
            {{ merchant.name }}
          </span>
          <span class="is-size-6 has-text-weight-light">{{ shortUrl }}</span>
        </div>
      </div>
      <b-button class="ml-auto" type="is-blue" @click="goToUrl()">{{
        $t("af:store.continue_to_the_page")
      }}</b-button>
    </div>
    <div class="card store-browser">
      <div class="card-content p-0 pb-5">
        <div
          class="columns is-mobile m-0 p-4 is-align-items-center store-browser__bar"
        >
          <div class="column">
            <b-icon
              icon-pack="fa"
              icon="circle-notch"
              size="is-medium"
              class="cursor-pointer"
              @click.native="refresh()"
            />
          </div>
          <div
            class="column is-two-quarters-mobile is-three-quarters-tablet is-one-quarter-desktop py-3 is-flex is-justify-content-center store-browser__bar-field"
          >
            <b-icon
              icon-pack="fa"
              icon="lock"
              class="is-inline-block mr-1 store-browser__bar-lock"
              size="is-small"
            />
            {{ shortUrl }}
          </div>

          <div class="column is-flex is-justify-content-end">
            <b-icon
              icon-pack="fa"
              icon="heart"
              size="is-medium"
              class="cursor-pointer"
            />
          </div>
        </div>
        <figure class="image is-4by3 m-0">
          <iframe
            id="storeBrowser"
            class="has-ratio"
            width="640"
            height="480"
            :src="merchant.website"
            allowfullscreen
            ref="storeBrowser"
          />
        </figure>
      </div>
    </div>
  </div>
  <LoadingSkeleton v-else />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Store",
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
  },
  components: {
    LoadingSkeleton: () => import("@/components/LoadingSkeleton"),
    ImageLoader: () => import("@/components/document/ImageLoader.vue"),
  },
  data() {
    return {
      merchant: {},
      loaded: false,
    };
  },
  computed: {
    ...mapGetters({
      getMerchant: "merchant/getMerchant",
    }),
    shortUrl() {
      return this.merchant.website.split("://")[1].split("/")[0];
    },
  },
  methods: {
    ...mapActions({
      fetchMerchant: "merchant/fetchMerchant",
    }),

    refresh() {
      const iframe = this.$refs.storeBrowser;
      iframe.src = iframe.src;
    },

    goToUrl() {
      window.open(this.merchant.website);
    },

    async fetch() {
      if (!this.getMerchant(this.storeId)) {
        await this.fetchMerchant(this.storeId);
      }
      this.merchant = this.getMerchant(this.storeId);
    },
  },
  async created() {
    await this.fetch();
    this.loaded = true;
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/colors.scss";

.store {
  .merchant-logo {
    display: inline-flex;
    border: 7px solid white;
    border-radius: 50%;
  }
  .store-browser {
    border-radius: 24px;

    &__bar-field {
      background-color: $grey-2;
      border-radius: 8px;
    }

    &__bar-lock {
      color: $grey-icon;
    }
  }
}
</style>
